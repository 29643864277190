.box-operators {
    flex-direction: row;
    justify-content: center;
    padding: 16px 16px 0 16px;
}

.button-delete {
    background-color: #2962FF !important;
    padding: 8px 8px 2px 8px;
    cursor: pointer
}

.button-operators{
    cursor: pointer;
    font-weight: bold;
    padding: 10px 20px;
    margin: 3px;
    background-color: #2962FF !important;
    color: white !important;
}

.box-expression {
    background-color: rgba(143,143,143,0.17);
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    align-items: center;
}

.box-operators-text {
    flex-direction: row;
    justify-content: center;
    padding: 0 16px;
    margin-bottom: 15px;
}
