.box-header {
    width: auto;
    display: flex;
    flex-direction: row;
    position: relative;
}

.header-date {
    min-width: 90px;
    max-width: 90px;
    font-size: 15px;
    height: 46px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, .12);
}

.line {
    width: 100%;
    min-width: 90px;
    /*max-width: 90px;*/
    font-size: 15px;
    height: 46px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, .12);
}

.line p {
    font-size: 15px;
}

.line-date p {
    font-size: 15px;
    color: #000000;
}

.header-date p {
    font-size: 15px;
    color: #FFFFFF;
}

.value-container {
    min-width: 150px;
    font-size: 15px;
    height: 46px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, .12);
    overflow: hidden;
}

.line-value {
    height: 25px;
    width: 100%;
    background-color: #2962FF;
}

.line-value p {
    font-size: 12px;
    font-weight: 700;
}

.person-add {
    color: #2962FF;
}

.mat-repeat-container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 0;
    position: relative;
    margin-top: 16px;
}

.mat-repeat-scroller {
    overflow-y: auto;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: 0;
    padding: 0;
    position: relative;
    right: 0;
    top: 0;
}

.warn {
    background-color: #FFEBEE;
}

.warn p {
    color: #EA4141;
    font-weight: bold;
}

.info {
    background-color: #6085e0;
}
