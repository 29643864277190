.box-header {
    width: auto;
    display: flex;
    flex-direction: row;
    position: relative;
}

.header-date {
    min-width: 90px;
    max-width: 90px;
    font-size: 15px;
    height: 46px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, .12);
    border-right: 0;
    border-top: 0;
}

.line-date {
    min-width: 90px;
    max-width: 90px;
    font-size: 15px;
    height: 46px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, .12);
    border-right: 0;
    border-top: 0;
}

.line-date p {
    font-size: 15px;
    color: #000000;
}

.header-date p {
    font-size: 15px;
    color: #FFFFFF;
}

.product {
    min-width: 150px;
    max-width: 150px;
    font-size: 15px;
    height: 46px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, .12);
    border-right: 0;
    border-top: 0;
    overflow: hidden;
}

.product p {
    line-height: normal;
}

.week-date {
    height: 25px;
    width: 100%;
    background-color: #2962FF;
}

.week-date p {
    font-size: 12px;
    font-weight: 700;
}

.person-add {
    color: #2962FF;
}

.mat-repeat-container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 0;
    position: relative;
    margin-top: 16px;
}

.mat-repeat-scroller {
    overflow-y: auto;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.warn {
    background-color: #FF8A80;
}

.warn p {
    color: rgba(0, 0, 0, .7);
    font-weight: bold;
}

.yellow {
    background-color: #FFC107;
}

.yellow p {
    color: rgba(0, 0, 0, .6);
    font-weight: bold;
}

.green {
    background-color: #C8E6C9;
}

.green p {
    color: rgba(0, 0, 0, .6);
}

.info {
    background-color: #E3F2FD;
}

.info p {
    color: rgba(0, 0, 0, .6);
}

.MuiFormControl-root {
    margin-bottom: 0 !important;
}

.labels {
    margin-top: -34px;
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label-color {
    width: 16px;
    height: 16px;
    background-color: #1ceab6;
    border-radius: 4px;
    margin-right: 4px;
}

.label-color.warn {
    background-color: #FF8A80;
}

.label-color.yellow {
    background-color: #FFC107;
}

.label-color.green {
    background-color: #C8E6C9;
}

.label-color.info {
    background-color: #E3F2FD;
    border: 1px solid #8eb0c8;
}
