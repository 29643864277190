.rbc-month-row {
    flex-basis: inherit;
    min-height: 80px;
}

.rbc-header {
    padding: 6px 3px;
    font-weight: lighter;
}

.button-add-calendar {
    opacity: 0;
    z-index: 999;
    border-radius: 0;
}

.button-add-calendar:hover {
    opacity: 1 !important;
    color: #2962FF !important;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.04) !important;
}


.rbc-row-content-scroll-container .rbc-row:last-child {
    padding-bottom: 40px;
}

.rbc-event-hover:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}
