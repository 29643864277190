.box-header {
    width: auto;
    display: flex;
    flex-direction: row;
    position: relative;
}

.header-date {
    min-width: 90px;
    max-width: 90px;
    font-size: 15px;
    height: 46px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, .12);
    border-right: 0;
    border-top: 0;
}

.line-date {
    min-width: 90px;
    max-width: 90px;
    font-size: 15px;
    height: 46px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, .12);
    border-right: 0;
    border-top: 0;
}

.line-date p {
    font-size: 15px;
    color: #000000;
}

.header-date p {
    font-size: 15px;
    color: #FFFFFF;
}

.product {
    min-width: 150px;
    max-width: 150px;
    font-size: 15px;
    height: 46px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, .12);
    border-right: 0;
    border-top: 0;
    overflow: hidden;
    gap: 8px;
}

.product p {
    line-height: normal;
}

.week-date {
    height: 25px;
    width: 100%;
    background-color: #2962FF;
}

.week-date p {
    font-size: 12px;
    font-weight: 700;
}

.info {
    background-color: #E3F2FD;
}

.info p {
    color: rgba(0, 0, 0, .6);
}
