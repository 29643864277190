.main-content-planning {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
}

.box-header {
    width: auto;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    z-index: 3;
}

.box-header > :first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    min-width: 200px;
    max-width: 200px;
}

.month {
    width: 100%;
    min-width: 100%;
    height: 28px;
    box-sizing: border-box;
    background-color: #45485F;
    padding-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid rgba(255,255,255,.5);
}

.month p {
    font-weight: 700;
    color: #FFFFFF;
    font-size: .9rem;
    position: sticky;
}

.phantom-box {
    height: 46px;
    width: 100px;
    background-color: #FFFFFF;
}

.body-planning {
    position: absolute;
    bottom: 0;
    top: 150px;
    left: 0;
    right: 0;
    overflow: scroll;
    background-color: #e5e5e5;
}

.smt-line-name {
    background-color: #2537a8;
}

.manual-line-name p,
.smt-line-name p,
.smt-shift-name p,
.manual-shift-name p {
    font-weight: 700;
    color: #FFFFFF;
    line-height: normal;
}

.smt-line-name .MuiSvgIcon-root,
.manual-line-name .MuiSvgIcon-root {
    color: #FFFFFF;
    margin-bottom: 8px;
}

.manual-line-name {
    background-color: #2962FF;
    text-align: center;
}

.sticky-bg {
    background-color: #FFFFFF;
    position: sticky;
    left: 0;
    z-index: 2;
}

.smt-line-name,
.manual-line-name {
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    padding: 0 16px;
    border-radius: 8px;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 100%;
}

.shifts {
    width: 100%;
    background-color: #FFFFFF;
}

.header-planning {
    background-color: #e5e5e5;
}

.header-day {
    margin: 1px;
    min-width: 75px;
    max-width: 75px;
    font-size: 15px;
    height: 46px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, .12);
    border-radius: 6px;
    overflow: hidden;
}

.week-day {
    height: 25px;
    width: 100%;
    background-color: #2962FF;
}

.week-day p {
    color: #FFFFFF;
}

.week-day p,
.header-number-day p {
    font-size: 10px;
    font-weight: 700;
}

.header-number-day p {
    font-size: 14px;
}

.smt-shift-name,
.manual-shift-name {
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    padding: 0;
    z-index: 2;
    border-radius: 8px;
    height: 100%;
    min-height: 46px;
    min-width: 100px;
    max-width: 100px;
    position: sticky;
    left: 100px;
    text-align: center;
}

.smt-shift-name {
    background-color: #2537a8;
}

.manual-shift-name {
    background-color: #2962FF;
}
.holiday,
.weekend,
.day {

    height: 46px;
    min-width: 75px;
    border-radius: 20px !important;
    max-width: 75px;
    border: 1px solid #e3e3e3;
    font-size: 13px;
    font-weight: 500;
    background-color: #FFFFFF;
    position: relative;
}

.used-day {

    height: 100%;
    min-width: 75px;
    border-radius: 20px !important;
    max-width: 75px;
    border: 1px solid #e3e3e3;
    font-size: 13px;
    font-weight: 500;
    background-color: #FFFFFF;
    position: relative;
}

.day .MuiTypography-root {
    font-weight: 700;
}

.day .person-add {
    color: #45485F;
}

.weekend {
    background-color: #cecdcf;
}

.holiday {
    background: rgb(90, 170, 157) !important;
    background: linear-gradient(0deg, rgba(90, 170, 157, 1) 0%, rgba(107, 187, 174, 1) 100%) !important;
}

.holiday .ic-holiday {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url("../../../../assets/images/ic-holiday.svg") no-repeat;
}

.days-alerts {
    position: absolute;
    right: 0;
    top: -4px;
    height: 14px;
    padding: 0;
}

.marker {
    width: 15px;
    height: 15px;
    line-height: 10px;
    justify-content: center;
    font-size: 15px;
    color: #ffffff;
    padding: 2px;
    border-radius: 100px;
    z-index: 1;
    display: flex;
    position: absolute;
    font-weight: bold;
    top: -1px;
    right:-1px;
    background-color: #EA4141;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.marker-color {
    position: relative;
    margin-left: 16px;
    margin-right: 8px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    top: 3px;
}

.marker-color.orange {
    background-color: orange;
}

.tooltip {
    z-index: 5;
}

.menu-demand {
    overflow-y: auto;
    max-width: 1200px;
    z-index: 9;
}

.no-break-text {
    white-space: nowrap;
}
