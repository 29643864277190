.boxMarker {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
}

.alertMarker {
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    padding: 2px;
    border-radius: 100px;
    z-index: 1;
    display: flex;
    background-color: #EA4141;
    cursor: default;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.alertMarker svg {
    width: 12px;
    height: 12px;
}