.background-screen {
    display: flex;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('../../../assets/images/bg-login.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.dashboard-planning {
    position: relative;

    .main-content-planning {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .box-months {
        width: auto;
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        overflow-y: hidden;
        position: relative;
        z-index: 3;
    }

    .box-months > :first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        min-width: 480px;
        max-width: 480px;
    }

    .month {
        display: flex;
        width: 100%;
        min-width: 100%;
        height: auto;
        box-sizing: border-box;
        background-color: #45485F;
        padding: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-right: 1px solid rgba(255,255,255,.5);
    }

    .month p {
        font-weight: 700;
        color: #FFFFFF;
        font-size: 1.4vw;
        position: sticky;
        line-height: normal;
    }

    .phantom-box {
        height: auto;
        width: 115px;
        background-color: #FFFFFF;
    }

    .body-planning {
        display: flex;
        position: relative;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        background-color: #e5e5e5;
        flex-direction: column;
    }

    .smt-line-name {
        background-color: #2537a8;
    }

    .manual-line-name p,
    .smt-line-name p,
    .smt-shift-name p,
    .manual-shift-name p {
        font-weight: 650;
        color: #FFFFFF;
        line-height: normal;
        font-size: 1.4vw;
    }

    .smt-line-name .MuiSvgIcon-root,
    .manual-line-name .MuiSvgIcon-root {
        color: #FFFFFF;
        margin-bottom: 8px;
    }

    .manual-line-name {
        background-color: #2962FF;
        text-align: center;
    }

    .sticky-bg {
        background-color: #FFFFFF;
        position: sticky;
        left: 0;
        z-index: 2;
        width: auto;
    }

    .smt-line-name,
    .manual-line-name {
        border-bottom: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        padding: 0 16px;
        border-radius: 8px;
        width: 100px;
        min-width: 240px;
        max-width: 240px;
        height: 100%;
        display: flex;
        text-align: center;
    }

    .shifts {
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
    }

    .header-planning {
        background-color: #FFFFFF;
        display: flex;
    }

    .header-day {
        min-width: auto;
        max-width: none;
        height: auto;
        box-sizing: border-box;
        background-color: #ffffff;
        border: 1px solid #FFFFFF;
        border-radius: 6px;
        overflow: hidden;
        margin: 0 !important;
    }

    .week-day, .weekend-day {
        height: auto;
        width: 100%;
        background-color: #2962FF;
        padding: 16px 0;
    }

    .weekend-day {
        background-color: #2962FF;
    }

    .week-day p,
    .weekend-day p {
        color: #FFFFFF;
    }

    .week-day p,
    .weekend-day p,
    .header-number-day p {
        font-size: 1.2vw;
        font-weight: 700;
        white-space: nowrap;
        text-align: center;
        line-height: normal;
    }

    .smt-shift-name,
    .manual-shift-name {
        border-bottom: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        padding: 16px 0;
        z-index: 2;
        border-radius: 8px;
        height: 100%;
        min-width: 240px;
        max-width: 240px;
        position: relative;
        left: 0;
        text-align: center;
    }

    .smt-shift-name {
        background-color: #2537a8;
    }

    .manual-shift-name {
        background-color: #2962FF;
    }
    .holiday,
    .weekend,
    .day {
        height: 52px;
        min-width: auto;
        max-width: none;
        border-radius: 4px;
        border: 1px solid #e3e3e3;
        font-size: 1.4vw;
        font-weight: 500;
        background-color: #FFFFFF;
        position: relative;
    }

    .used-day {
        height: 100%;
        min-width: auto;
        max-width: none;
        border-radius: 4px;
        border: 1px solid #e3e3e3;
        position: relative;
    }

    .day .MuiTypography-root {
        font-weight: 700;
    }

    .day .person-add {
        color: #45485F;
        font-size: 1.7vw;
    }

    .weekend {
        background-color: #cecdcf;
    }

    .holiday {
        background: rgb(90, 170, 157) !important;
        background: linear-gradient(0deg, rgba(90, 170, 157, 1) 0%, rgba(107, 187, 174, 1) 100%) !important;
    }

    .holiday .ic-holiday {
        width: 24px;
        height: 24px;
        display: inline-block;
    }

    .days-alerts {
        position: absolute;
        right: 0;
        top: -4px;
        height: 14px;
        padding: 0;
    }

    .marker {
        width: 24px;
        height: 24px;
        line-height: 20px;
        justify-content: center;
        font-size: 20px;
        color: #ffffff;
        padding: 2px;
        border-radius: 100px;
        z-index: 1;
        display: flex;
        position: absolute;
        font-weight: bold;
        top: -1px;
        right:-1px;
        background-color: #EA4141;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    }

    .marker-color {
        position: relative;
        margin-left: 16px;
        margin-right: 8px;
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        top: 3px;
    }

    .marker-color.orange {
        background-color: orange;
    }

    .tooltip {
        z-index: 5;
    }

    .menu-demand {
        overflow-y: auto;
        max-width: 1200px;
        z-index: 9;
    }

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .container {
        display: flex;
        flex-direction: row;
        position: absolute;
        right: 10px;
        bottom: 10px;
        margin: 0 10px;
    }

    .day-full {
        height: 100%;
    }
    .day-half {
        height: 50%;
    }

    .weekend {
        min-width: 120px;
        max-width: 120px;
    }

    .weekend-day {
        min-width: 120px;
        max-width: 120px;
        background-color: #2962FF;
    }

    .header-day:has(> .weekend-day) {
        min-width: 120px;
        max-width: 120px;
    }

    .weekend-block:has(.weekend) {
        width: 120px;
    }

    @media screen and (min-width: 1920px) {
        .weekend {
            min-width: 160px;
            max-width: 160px;
        }

        .weekend-day {
            min-width: 160px;
            max-width: 160px;
            background-color: #2962FF;
        }

        .header-day:has(> .weekend-day) {
            min-width: 160px;
            max-width: 160px;
        }

        .weekend-block:has(.weekend) {
            width: 160px;
        }
    }

    @media screen and (min-width: 2560px) {
        .weekend {
            min-width: 220px;
            max-width: 220px;
        }

        .weekend-day {
            min-width: 220px;
            max-width: 220px;
            background-color: #2962FF;
        }

        .header-day:has(> .weekend-day) {
            min-width: 220px;
            max-width: 220px;
        }

        .weekend-block:has(.weekend) {
            width: 220px;
        }
    }
}

.text {
    color: #FFFFFF;
    font-size: 1.1vw !important;
    font-weight: bold !important;
    line-height: normal !important;
    margin: 0 0 0 32px !important;
    padding: 0 !important;
}

.txt-totals {
    font-size: 1.6vw !important;
    line-height: normal !important;
}

.txt-description {
    font-size: 1.6vw !important;
    font-weight: 600 !important;
    line-height: normal !important;
}
