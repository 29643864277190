*, *:before, *:after {
    box-sizing: border-box;
    list-style: none;
}

:root {
    --default-color: #45485F;
}

body, html {
    color: var(--default-color) !important;
    height: 100% !important;
}

.header {
    box-shadow: none !important;
}

.MuiTypography-h5,
.MuiTypography-h6 {
    font-weight: 700 !important;
    color: var(--default-color);
}

.MuiTypography-h6 {
    font-size: 1.1em !important;
}

.floating-button {
    position: absolute;
    right: 24px;
    top: -52px;
    z-index: 1;
    display: flex;
    gap: 16px;
}

.floating-title {
    position: absolute;
    right: 24px;
    left: 15px;
    top: -44px;
    z-index: 1;
}

.floating-title-progress {
    position: absolute;
    align-self: center;
    right: 24px;
    top: -44px;
    z-index: 1;

    h5 {
        color: #2962FF !important;
    }
}

.floating-title-item {
    position: absolute;
    right: 24px;
    left: 0;
    top: -54px;
    z-index: 1;
}

.MuiBox-root {
    width: 100%;
}

.MuiBox-root .css-19midj6 {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
}

.MuiAppBar-root, .MuiButton-root {
    box-shadow: none;
    font-weight: 700;
    min-width: fit-content !important;
}

.css-1ps6pg7-MuiPaper-root {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
}

.MuiButton-startIcon .svg-inline--fa {
    font-size: 16px;
}

.css-poa6bm-MuiButtonBase-root-MuiIconButton-root {
    color: var(--default-color) !important;
}

.logo {
    vertical-align: middle;
    line-height: 0;
}

.main-container {
    position: relative;
    padding: 24px 15px !important;
}

.MuiButton-root {
    box-shadow: none !important;
}

.MuiTypography-root {
    font-weight: 700;
    color: var(--default-color);
}

.MuiListItemIcon-root {
    color: var(--default-color) !important;
}

.MuiMenuItem-root:hover .MuiListItemIcon-root {
    color: #2962FF !important;
}

.MuiMenuItem-root:hover .MuiListItemText-root .MuiTypography-root {
    color: #2962FF !important;
}

.MuiListItemText-root .MuiTypography-root {
    font-weight: 500 !important;
}

/* TABLE */

.MuiTableRow-head {
    background-color: #2962FF !important;
}

.MuiTableCell-head {
    font-weight: 700 !important;
    color: #FFFFFF !important;
    font-size: 13px !important;
}

.MuiTableCell-body {
    font-weight: 500 !important;
    color: var(--default-color) !important;
    font-size: 13px !important;
}

.MuiTableRow-root:hover {
    background-color: #E3F2FD;
}

.MuiTableRow-root:hover:nth-child(even) {
    background-color: #E3F2FD;
}

.MuiTableRow-root:nth-child(even) {
    background-color: #f9f9f9;
}

/* SIDENAV */

.sidenav.MuiDrawer-docked {
    min-width: 65px;
    max-width: 140px;
}

.sidenav .MuiListItem-root {
    padding: 0 8px;
}

.sidenav .MuiListItemButton-root:hover .MuiListItemIcon-root,
.sidenav .MuiListItemButton-root:hover .MuiTypography-root {
    color: #2962ff !important;
}

.sidenav .MuiListSubheader-root,
.sidenav .MuiListItemButton-root .MuiTypography-root {
    white-space: normal;
    text-align: center;
    line-height: normal;
}

.sidenav .MuiListItemButton-root .MuiTypography-root {
    display: block;
    font-size: 13px;
}

.sidenav .MuiListSubheader-root {
    margin-bottom: 8px;
}

.sidenav .MuiListItemIcon-root {
    margin-right: 0;
}

.sidenav .MuiListItemIcon-root .MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
}

.sidenav .MuiTypography-overline {
    margin: 10px 0 -6px 22px;
    color: #45485F;
    opacity: .8;
}

.sidenav .MuiListItemButton-root {
    min-height: 42px;
    border-radius: 4px;
    padding: 8px;
    display: block;
    text-align: center;
    line-height: normal
}

.sidenav .MuiListItemText-root {
    margin-top: 0;
    margin-bottom: 0;
    color: #45485F;
}

.sidenav .MuiListItemIcon-root {
    color: #45485F;
}

.sidenav .MuiListItemButton-root.active {
    background-color: #2962FF;
    color: #FFFFFF;
}

.sidenav .MuiListItemButton-root.active .MuiListItemIcon-root,
.sidenav .MuiListItemButton-root.active .MuiTypography-root {
    color: #FFFFFF !important;
}

.bg-home {
    background-image: url('/src/assets/images/bg-home.svg');
    height: calc(100vh - 229px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiInputLabel-formControl.formControl {
    top: 1px;
    background-color: #fff;
    z-index: 1;
    padding: 0 6px;
}

/* END SIDENAV */

/* SUBHEADER */

.subheader {
    background-color: #FFFFFF;
    border-bottom: 2px solid rgba(0, 0, 0, .12);
    height: 64px;
}

/* END SUBHEADER */

/* TAB */

.MuiButtonBase-root.MuiBottomNavigationAction-root {
    border: 1px solid #e3e3e3;
    border-bottom: 0;
}

.MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected {
    background-color: #2962ff;
    color: #FFFFFF !important;
}

/* END TAB */

/* DIALOG MODULES MENU */

.MuiLink-button.module-name {
    width: 100%;
    padding: 32px 8px;
    border-radius: 8px;
    border: 1px solid #1ceab6;
    background-color: #2537a8;
    color: #ffffff;
    text-decoration: none;
    font-size: 15px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0 10px 10px -5px;
}

.css-1yuhvjn {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12) !important;
}

.MuiLink-button.module-name .MuiSvgIcon-root {
    width: 3em !important;
    height: 3em !important;
    display: block;
    margin: auto;
}

.MuiLink-button.module-name:hover {
    transition: .2s;
    background-color: #2133A2;
}

.MuiLink-button.module-name:hover .MuiSvgIcon-root {
    transition: .2s;
    color: #1ceab6;
}

/* END DIALOG MODULES MENU */

.station-list tbody tr td:first-child {
    width: 30px;
    padding-right: 0;
}

.station-list tbody tr td:nth-child(2) {
    width: 22px;
    padding-right: 0;
    padding-left: 0;
}

.station-list tbody tr td:nth-child(3) {
    padding-left: 4px;
}

.list-icons {
    color: var(--default-color);
    opacity: .5;
}

.grid-container {
    padding: 48px 24px;
    display: flex;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.title-home {
    display: flex;
    flex-direction: row;
    align-items: center;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.custom-header {
    height: 48px !important;
    background-color: #2962FF;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 700;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    border-bottom: 1px solid #e2e2e2;
    color: #ffffff;
}

#cb-product:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #f44336;
}

.not-filled .MuiInputLabel-root {
    color: #f44336;
}

.not-filled .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
    border-color: #f44336;
}

.menu-demand {
    position: absolute;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    z-index: 1;
    border-right: 1px solid rgba(0, 0, 0, .12);
}

.demand-accordion {
    border-radius: 0 !important;
    box-shadow: none !important;
}

.demand-accordion-details {
    padding: 4px !important;
}

.demand-accordion-summary {
    background-color: #f5f5f5 !important;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
}

.badge-count {
    border-radius: 2px !important;
    background-color: #2962FF !important;
    margin-right: 4px !important;
    min-width: 24px !important;
    text-align: center !important;
}

.demand-card {
    min-height: 50px;
    margin: 2px;
    padding: 8px 12px;
    font-weight: bold;
    position: relative;
}

.accordion-history {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
    margin-bottom: 16px;
}

.icon-user {
    font-size: 60px;
}

.header-history {
    justify-content: space-between;
    background-color: #2962FF;
    padding: 12px 16px 4px 16px;
}

.MuiInputLabel-formControl.formControl {
    padding: 0;
}

.button-options {
    min-width: 90px !important;
}

.css-1l2d6is-MuiPaper-root {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
}

.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper, .ag-theme-material .ag-layout-auto-height .ag-center-cols-container, .ag-theme-material .ag-layout-print .ag-center-cols-clipper, .ag-theme-material .ag-layout-print .ag-center-cols-container {
    min-height: auto !important;
}

.css-1ip3epc-MuiGrid-root > .MuiGrid-item {
    padding: 0 !important;
}

.ag-header.ag-header-allow-overflow .ag-header-row {
    height: 48px !important;
}

.ag-header-allow-overflow {
    height: 48px !important;
    min-height: 48px !important;
}

.pagination-buttons {
    position: absolute;
    right: 29px;
}

.pagination-buttons-select {
    position: absolute;
    right: 52px;
}

.hide-buttons {
    display: none;
}

.required-field, .error-field {
    background-color: red;
    color: white !important;
    padding: 4px 8px;
    border-radius: 4px;
    position: absolute !important;
    right: 0 !important;
    top: -15px !important;
    z-index: 1 !important;
}

.error-required {
    color: red !important;
}

.error-autocomplete fieldset {
    border-color: red !important;
}

.error-autocomplete fieldset legend span {
    border-color: red !important;
}

.error-autocomplete:hover fieldset {
    border-color: red !important;
}

.error-label-autocomplete label {
    color: red !important;
}

.input-file input {
    cursor: pointer !important;
}

.input-file input[type="file"]::-webkit-file-upload-button {
    display: none;
}

.list-subheader {
    background-color: #2133A2 !important;
    color: #FFFFFF !important;
}

.MuiMenu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.button-sync {
    padding-right: 16px;
}

.card-paper {
    width: 100%;
    padding: 12px 24px;
    border-left: 3px solid #2962FF !important;
}

.chip-version {
    height: 22px !important;
    cursor: pointer !important;
}

.chip-version span {
    padding-left: 8px;
    padding-right: 8px;
}

.date-picker {
    min-height: 50px;
}

.date-picker-icon {
    right: 5px;
    top: 15%;
    cursor: pointer;
}

.active-line {
    width: 146px;
    background-color: #E3F2FD;
    padding: 4px 8px;
    border-left: 2px solid #2962FF;
    align-items: center !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px solid #ccc;
}

.MuiAccordionDetails-root {
    padding: 16px !important;
}

.shift-data {
    width: 146px;
    background-color: #FFFFFF;
    padding: 4px 8px;
    border-left: 1px solid rgba(0, 0, 0, .2);
    align-items: center !important;
}