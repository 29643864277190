.accordion-summary {
    background-color: #FFFFFF !important;
}

.badge-quantity {
    padding: 1px 8px !important;
    background-color: #2962FF !important;
    margin-right: 5px !important;
}

.badge-quantity p {
    font-weight: bold !important;
    color: #ffffff !important;
    line-height: inherit !important;
}
