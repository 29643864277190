.table-prs {
    border-radius: 5px;
}

.table-prs th,
.table-prs td {
    text-align: center;
}

.table-prs-actions {
    width: 5%;
    text-align: end !important;
}
